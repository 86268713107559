<template>
  <div>
    <div class="order-list-container">
      <header-back rootClassName="rootClassName2"></header-back>
      <div v-if="isFetching" class="py-10">
        <Loading />
      </div>
      <div
        v-else
        class="order-list-container1"
        v-for="item in orderList"
        :key="item.id"
      >
        <h1 class="order-list-text">Order</h1>
        <div class="order-list-item-order">
          <span class="order-list-text01">{{ date(item.created_at) }}</span>
          <span class="order-list-text02">
            <span class="order-list-text03"></span>
            <span class="order-list-text04">{{ item.invoice_number }}</span>
          </span>
          <div class="order-list-container2 flex-col md:flex-row gap-4 items-start">
            <div
              class="my-account-btn-delivery"
              v-if="item.status == 'order_complete'"
            >
              <span class="my-account-text18">Completed</span>
            </div>
            <div class="my-account-btn-paid" v-if="item.status == 'paid'">
              <span class="my-account-text18">In Process</span>
            </div>
            <div class="my-account-btn-paid" v-if="item.status == 'paid_off'">
              <span class="my-account-text18">In Process</span>
            </div>
            <div
              class="my-account-btn-waiting"
              v-if="item.status == 'waiting_for_product'"
            >
              <span class="my-account-text18">Waiting for Arrival</span>
            </div>
            <div
              class="my-account-btn-waiting"
              v-if="item.status == 'product_is_ready'"
            >
              <span class="my-account-text18">Arrived, waiting for payment</span>
            </div>
            <div
              class="my-account-btn-waiting"
              v-if="item.status == 'pending'"
            >
              <span class="my-account-text18">Pending</span>
            </div>
            <div
              class="my-account-btn-expired"
              v-if="item.status == 'unsuccess'"
            >
              <span class="my-account-text18">Waiting For Payment</span>
            </div>
            <div class="my-account-btn-send" v-if="item.status == 'order_sent'">
              <span class="my-account-text18">On Delivery</span>
            </div>
            <div class="my-account-btn-expired" v-if="item.status == 'expired'">
              <span class="my-account-text18">Cancelled</span>
            </div>
            <div class="my-account-btn-new" v-if="item.status == 'new_order'">
              <span class="my-account-text18">New Order</span>
            </div>
            <div
              class="order-list-btn-details cursor-pointer"
              @click="goPage('order-detail/' + item.id)"
            >
              <span class="order-list-text06">
                <span class="order-list-text07"></span>
                <span class="order-list-text08">See Details</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back';
  import Footer from '../components/footer';
  import Api from '../api';
  import moment from 'moment';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'OrderList',
    props: {},
    components: {
      HeaderBack,
      Footer,
      Loading,
    },
    data() {
      return {
        orderList: [],
        isFetching: false,
      };
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        try {
          this.isFetching = true;
          const { data } = await axios.get(Api.orderUrl);
          this.orderList = data.data;
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isFetching = false;
        }
      },
      date(date) {
        return moment(date).format('D MMMM YYYY');
      },
      goPage(page) {
        this.$router.push(page);
      },
    },
  };
</script>

<style scoped>
  .order-list-container {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
    padding-bottom: 46px;
  }
  .order-list-container1 {
    width: 600px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .order-list-text {
    color: #1f252c;
    font-family: Lora;
    margin-bottom: var(--dl-space-space-twounits);
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  .order-list-item-order {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .order-list-container1:not(:last-of-type) .order-list-item-order {
    border-bottom: 1px solid #fff;
    padding-bottom: 32px;
  }
  .order-list-text01 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1f252c;
  }
  .order-list-text02 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-list-text03 {
    font-family: Lora;
  }
  .order-list-text04 {
    font-family: Lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1f252c;
  }
  .order-list-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-btn-delivery {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
  }
  .order-list-text05 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .order-list-btn-details {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .order-list-text06 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .order-list-text07 {
    font-family: Montserrat;
  }
  .order-list-text08 {
    font-family: Montserrat;
  }
  .order-list-item-order1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .order-list-text09 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-list-text10 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-list-text11 {
    font-family: Lora;
  }
  .order-list-text12 {
    font-family: Lora;
  }
  .order-list-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .order-list-btn-delivery1 {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
  }
  .order-list-text13 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .order-list-btn-details1 {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .order-list-text14 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .order-list-text15 {
    font-family: Montserrat;
  }
  .order-list-text16 {
    font-family: Montserrat;
  }
  .order-list-item-order2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .order-list-text17 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-list-text18 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-list-text19 {
    font-family: Lora;
  }
  .order-list-text20 {
    font-family: Lora;
  }
  .order-list-container4 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .my-account-btn-delivery {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #1f7d45;
  }
  .my-account-btn-send {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #36b9cc;
  }
  .my-account-btn-paid {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f6c23e;
  }
  .my-account-btn-waiting {
    flex: 0 0 auto;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #36b9cc;
    padding: 0 20px;
  }
  .my-account-text18 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .my-account-btn-expired {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e74a3b;
  }
  .my-account-btn-new {
    flex: 0 0 auto;
    padding: 0 20px;
    height: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #858796;
  }
  .order-list-text21 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
  }
  .order-list-btn-details2 {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .order-list-text22 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .order-list-text23 {
    font-family: Montserrat;
  }
  .order-list-text24 {
    font-family: Montserrat;
  }
  @media (max-width: 991px) {
    .order-list-container1 {
      width: 100%;
      display: flex;
      overflow: auto;
      align-items: center;
      flex-direction: column;
      background-color: #eae3d9;
    }
    .order-list-container1 {
      width: 600px;
      display: flex;
      align-self: center;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }
    .order-list-text {
      color: #1f252c;
      font-family: Lora;
      margin-bottom: var(--dl-space-space-twounits);
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
    .order-list-item-order {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .order-list-text01 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1f252c;
    }
    .order-list-text02 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .order-list-text03 {
      font-family: Lora;
    }
    .order-list-text04 {
      font-family: Lora;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #1f252c;
    }
    .order-list-container2 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .order-list-btn-delivery {
      flex: 0 0 auto;
      padding: 0 20px;
      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .order-list-text05 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .order-list-btn-details {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .order-list-text06 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .order-list-text07 {
      font-family: Montserrat;
    }
    .order-list-text08 {
      font-family: Montserrat;
    }
    .order-list-item-order1 {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .order-list-text09 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .order-list-text10 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .order-list-text11 {
      font-family: Lora;
    }
    .order-list-text12 {
      font-family: Lora;
    }
    .order-list-container3 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .order-list-btn-delivery1 {
      flex: 0 0 auto;
      padding: 0 20px;
      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .order-list-text13 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .order-list-btn-details1 {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .order-list-text14 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .order-list-text15 {
      font-family: Montserrat;
    }
    .order-list-text16 {
      font-family: Montserrat;
    }
    .order-list-item-order2 {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .order-list-text17 {
      font-family: Montserrat;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .order-list-text18 {
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      margin-bottom: var(--dl-space-space-unit);
    }
    .order-list-text19 {
      font-family: Lora;
    }
    .order-list-text20 {
      font-family: Lora;
    }
    .order-list-container4 {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .my-account-btn-delivery {
      flex: 0 0 auto;
      padding: 0 20px;
      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f7d45;
    }
    .my-account-text18 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .my-account-btn-expired {
      flex: 0 0 auto;
      padding: 0 20px;
      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #7d1f1f;
    }
    .my-account-btn-new {
      flex: 0 0 auto;
      padding: 0 20px;
      height: 34px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #1f257d;
    }
    .order-list-text21 {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
    }
    .order-list-btn-details2 {
      flex: 0 0 auto;
      width: 200px;
      border: 1px solid #1f252c;
      height: var(--dl-size-size-small);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .order-list-text22 {
      font-style: normal;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .order-list-text23 {
      font-family: Montserrat;
    }
    .order-list-text24 {
      font-family: Montserrat;
    }
  }
  @media (max-width: 991px) {
    .order-list-container1 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .order-list-item-order {
      border-bottom: 1px solid #fff;
      padding-bottom: 32px;
    }
  }
</style>
